import { Route } from "@solidjs/router";
import { ROUTES } from "../const";
import { EditStage, EditMaterial, ShowSubmissionDetails, ShowSubmissionsTable } from "../views";
import { Ctrl } from ":mods";
import { EditModule } from "../views/edit-module";

export function AdminRoutes(props: Ctrl.Routes.model.AdminViewProps) {
  return (
    <>
      {/* core diff/static stage ids  */}
      <Route path={ROUTES.side.courses.foundation()} element={<EditStage {...props} stageID={1} />} />
      <Route path={ROUTES.side.courses.advanced()} element={<EditStage {...props} stageID={2} />} />
      {/* after that the only diff is in route name for clear component calls */}
      <Route
        element={<EditModule {...props} />}
        path={[ROUTES.side.courses.foundation_module(), ROUTES.side.courses.advanced_module()]}
      />
      <Route
        path={[ROUTES.side.courses.foundation_material(), ROUTES.side.courses.advanced_material()]}
        // FIXME: fix as any
        element={<EditMaterial {...props} />}
      />
      <Route path={[ROUTES.side.courses.foundation_submissions_list()]} element={<ShowSubmissionsTable {...props} />} />
      <Route
        path={ROUTES.side.courses.foundation_submission_student()}
        element={<ShowSubmissionDetails {...props} />}
      />
    </>
  );
}
